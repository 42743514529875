import React, { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Slider from "react-slick";

// Images
import infolob from '../../images/event/infolob-banner.jpg';
import idealabs from '../../images/event/idealabs.jpg';
import gtt from '../../images/event/gtt-banner.jpg';

// Content
const content = [
	{
		thumb: infolob,
		title: "Infolob Hired - 5 Full Stack Developer's",
		date: "12 Dec 2022",
		text: "Infolob - hired 5 full Stack developer in December 2022, to develop Cloud App using MERN Stack, Python, Go Stack.A multi-award winning Oracle partner — enabling organizations migrate to Cloud and achieve Digital Transformation. ",
		details: [
			{
				company: "GTT",
				name: "Alfaz",
				about_dec: {
					des1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
					des2: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
				},
				video: "",
				testimonials: {
					image: "",
					name: "",
					course: "",
					text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
				}
			}
		]
	},
	{
		thumb: gtt,
		title: "GermanTown - offered 6 LPA for freshers",
		date: "4 April 2021",
		text: "GermanTown Technologies hired 5 MERN Stack Developers from Pro Stack Academy with a package of 6 LPA for freshers. German Town Technologies started new chapter in Bangalore, India, to enable clinicians to make the right decisions for their patients. ",
	},
	{
		thumb: idealabs,
		title: "Idea Labs - Hired 6 React Developers",
		date: "4 April 2021",
		text: "IdeyaLabs - Certified organization, is a main company of Consulting, Product Development to purchasers throughout the globe, providing End-to-End product development services. ired 6 React JS Developer from Pro Stack Academy.",
		details: [
			{
				company: "Idea Labs",
				name: "Aman",
				about_dec: {
					des1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
					des2: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
				},
				video: "",
				testimonials: {
					image: "",
					name: "",
					course: "",
					text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
				}
			}
		]
	},



]

const UpcomingEventSlider = () => {
	const history = useHistory();
	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};

	return (
		<>
			<div className="section-area section-sp1">
				<div className="container">
					<div className="row">
						<div className="col-md-12 heading-bx left">
							<h2 className="title-head">Success <span>Stories</span></h2>
							<p>It is a long established fact that a reader will be distracted by the readable content of a page</p>
						</div>
					</div>
					<Slider {...settings} className="upcoming-event-carousel slick-slider owl-btn-1 m-b15">
						{content.map((item) => (
							<div className="slider-item">
								<div className="event-bx">
									<div className="action-box">
										<img src={item.thumb} alt="" />
										{/* <div className="event-time">
											<div className="event-date">29</div>
											<div className="event-month">October</div>
										</div> */}
									</div>
									<div className="info-bx d-flex">
										<div className="event-info">
											<ul className="media-post">
												<li><Link onClick={() => history.push("/success-details", { state: item.details })} ><i className="fa fa-clock-o" ></i>{item.date}</Link></li>
												<li><Link onClick={() => history.push("/success-details", { state: item.details })} ><i className="fa fa-map-marker"></i> Pro Stack Academy</Link></li>
											</ul>
											{/* <h4 className="event-title"><Link onClick={() => history.push("/success-details", { state: item.details })} > {item.title}</Link></h4> */}
											<h4 className="event-title"><Link /* onClick={() => history.push("/success-details", { state: item.details })} */ > {item.title}</Link></h4>
											<p>{item.text}</p>
										</div>
									</div>
								</div>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</>
	);
}
// class UpcomingEventSlider extends Component {
// 	render() {

// 		const settings = {
// 			infinite: true,
// 			speed: 500,
// 			slidesToShow: 2,
// 			slidesToScroll: 1,
// 			responsive: [
// 				{
// 					breakpoint: 1200,
// 					settings: {
// 						slidesToShow: 2,
// 					}
// 				},
// 				{
// 					breakpoint: 1024,
// 					settings: {
// 						slidesToShow: 2,
// 					}
// 				},
// 				{
// 					breakpoint: 768,
// 					settings: {
// 						slidesToShow: 2,
// 					}
// 				},
// 				{
// 					breakpoint: 600,
// 					settings: {
// 						slidesToShow: 1,
// 					}
// 				}
// 			]
// 		};

// 		return (
// 			<>
// 				<div className="section-area section-sp1">
// 					<div className="container">
// 						<div className="row">
// 							<div className="col-md-12 heading-bx left">
// 								<h2 className="title-head">Success <span>Stories</span></h2>
// 								<p>It is a long established fact that a reader will be distracted by the readable content of a page</p>
// 							</div>
// 						</div>
// 						<Slider {...settings} className="upcoming-event-carousel slick-slider owl-btn-1 m-b15">
// 							{content.map((item) => (
// 								<div className="slider-item">
// 									<div className="event-bx">
// 										<div className="action-box">
// 											<img src={item.thumb} alt="" />
// 											{/* <div className="event-time">
// 												<div className="event-date">29</div>
// 												<div className="event-month">October</div>
// 											</div> */}
// 										</div>
// 										<div className="info-bx d-flex">
// 											<div className="event-info">
// 												<ul className="media-post">
// 													<li><Link to="/success-details"><i className="fa fa-clock-o"></i>{item.date}</Link></li>
// 													<li><Link to="/success-details"><i className="fa fa-map-marker"></i> Pro Stack Academy</Link></li>
// 												</ul>
// 												<h4 className="event-title"><Link to="/success-details">{item.title}</Link></h4>
// 												<p>{item.text}</p>
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							))}
// 						</Slider>
// 					</div>
// 				</div>
// 			</>
// 		);
// 	}
// }

export default UpcomingEventSlider;