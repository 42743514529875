import React, { Component, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import Header from "../../layout/header/Header1";
import Footer from "../../layout/footer/Footer1";
import Slider from "react-slick";

// Images
import aboutPic1 from '../../../images/about/pic1.jpg';
import bannerImg from '../../../images/banner/banner1.jpg';

// Images
import testiPic1 from '../../../images/testimonials/pic1.jpg';
import testiPic2 from '../../../images/testimonials/pic2.jpg';
import testiPic3 from '../../../images/testimonials/pic3.jpg';

// Content
const content = [
    {
        thumb: testiPic1,
        name: "Narasimha Reddy",
        work: "Full Stack Trainer",
        text: "A well organized highly experienced professional with IT industrial experience (Full Stack - Technologies) of over 12 years in consulting and solution industries.",
    },
    {
        thumb: testiPic2,
        name: "Gautham",
        work: "Developer",
        text: "A well organized highly experienced professional with IT industrial experience (Full Stack - Technologies) of over 12 years in consulting and solution industries.",
    },
    {
        thumb: testiPic3,
        name: "Atwood",
        work: "Designer",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type...",
    },
]
const OurStory1 = () => {
    const [isOpen, setisOpen] = useState(false);
    const openModal = () => setisOpen(true);
    const data = useLocation();
    console.log(data, "hi")
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <>
            <Header />
            {
                data?.state?.state?.length ? <>
                    <div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Success Stories - {data?.state?.state[0]?.company}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb-row">
                        <div className="container">
                            <ul className="list-inline">
                                <li><Link to="/">Home</Link></li>
                                <li>Success Stories</li>
                            </ul>
                        </div>
                    </div>
                    <div className="section-area bg-gray section-sp2 our-story">
                        <div className="container">
                            <div className="row align-items-center d-flex">
                                <div className="col-lg-5 col-md-12 m-b30">
                                    <div className="heading-bx left mb-3">
                                        <h2 className="title-head">{data?.state?.state[0]?.name}</h2>
                                        <p>{data?.state?.state[0]?.about_dec?.des1}</p>
                                    </div>
                                    <p className="m-b30">{data?.state?.state[0]?.about_dec?.des2}</p>
                                    <Link to="/contact" className="btn">100% Placements</Link>
                                </div>
                                <div className="col-lg-7 col-md-12 p-lr">
                                    <div className="video-bx">
                                        <img src={aboutPic1} alt="" />
                                        <Link onClick={openModal} to="#" className="popup-youtube video"><i className="fa fa-play"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalVideo channel='youtube' isOpen={isOpen} videoId='x_sJzVe9P_8' onClose={() => setisOpen(false)} />
                    {/* Testimonials */}
                    <div className="section-area section-sp2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 heading-bx left">
                                    <h2 className="title-head">What People <span>Say</span></h2>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page</p>
                                </div>
                            </div>

                            <Slider {...settings} className="testimonial-carousel slick-slider owl-btn-1">
                                {content.map((item) => (
                                    <div className="slider-item">
                                        <div className="testimonial-bx">
                                            <div className="testimonial-head">
                                                <div className="testimonial-thumb">
                                                    <img src={item.thumb} alt="" />
                                                </div>
                                                <div className="testimonial-info">
                                                    <h5 className="name">{item.name}</h5>
                                                    <p>-{item.work}</p>
                                                </div>
                                            </div>
                                            <div className="testimonial-content">
                                                <p>{item.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </> : <div style={{ padding: "50px 0px", width: "92%", margin: "auto", textAlign: "center" }}>No Data found</div>
            }
            <Footer />
        </>
    );
}
// class OurStory1 extends Component {

//     constructor() {
//         super()
//         this.state = {
//             isOpen: false
//         }
//         this.openModal = this.openModal.bind(this)
//     }
//     openModal() {
//         this.setState({ isOpen: true })
//     }

//     render() {
//         const settings = {
//             infinite: true,
//             speed: 500,
//             slidesToShow: 2,
//             slidesToScroll: 1,
//             responsive: [
//                 {
//                     breakpoint: 1200,
//                     settings: {
//                         slidesToShow: 2,
//                     }
//                 },
//                 {
//                     breakpoint: 1024,
//                     settings: {
//                         slidesToShow: 2,
//                     }
//                 },
//                 {
//                     breakpoint: 768,
//                     settings: {
//                         slidesToShow: 2,
//                     }
//                 },
//                 {
//                     breakpoint: 600,
//                     settings: {
//                         slidesToShow: 1,
//                     }
//                 }
//             ]
//         };
//         return (
//             <>
//                 <Header />
//                 <div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
//                     <div className="container">
//                         <div className="page-banner-entry">
//                             <h1 className="text-white">Success Stories - GTT</h1>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="breadcrumb-row">
//                     <div className="container">
//                         <ul className="list-inline">
//                             <li><Link to="/">Home</Link></li>
//                             <li>Success Stories</li>
//                         </ul>
//                     </div>
//                 </div>
//                 <div className="section-area bg-gray section-sp2 our-story">
//                     <div className="container">
//                         <div className="row align-items-center d-flex">
//                             <div className="col-lg-5 col-md-12 m-b30">
//                                 <div className="heading-bx left mb-3">
//                                     <h2 className="title-head">Alfaz</h2>
//                                     <p>It is a long established fact that a reader will be distracted by the readable content of a page</p>
//                                 </div>
//                                 <p className="m-b30">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
//                                 <Link to="/contact" className="btn">100% Placements</Link>
//                             </div>
//                             <div className="col-lg-7 col-md-12 p-lr">
//                                 <div className="video-bx">
//                                     <img src={aboutPic1} alt="" />
//                                     <Link onClick={this.openModal} to="#" className="popup-youtube video"><i className="fa fa-play"></i></Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='x_sJzVe9P_8' onClose={() => this.setState({ isOpen: false })} />
//                 {/* Testimonials */}
//                 <div className="section-area section-sp2">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-12 heading-bx left">
//                                 <h2 className="title-head">What People <span>Say</span></h2>
//                                 <p>It is a long established fact that a reader will be distracted by the readable content of a page</p>
//                             </div>
//                         </div>

//                         <Slider {...settings} className="testimonial-carousel slick-slider owl-btn-1">
//                             {content.map((item) => (
//                                 <div className="slider-item">
//                                     <div className="testimonial-bx">
//                                         <div className="testimonial-head">
//                                             <div className="testimonial-thumb">
//                                                 <img src={item.thumb} alt="" />
//                                             </div>
//                                             <div className="testimonial-info">
//                                                 <h5 className="name">{item.name}</h5>
//                                                 <p>-{item.work}</p>
//                                             </div>
//                                         </div>
//                                         <div className="testimonial-content">
//                                             <p>{item.text}</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))}
//                         </Slider>
//                     </div>
//                 </div>
//                 <Footer />

//             </>
//         );
//     }
// }

export default OurStory1;