import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header/Header1";
import Footer from "../layout/footer/Footer";

// Elements
import ProjectsContent from "../elements/Projects-Content";

// Images
import bannerImg from '../../images/banner/banner1.jpg';

class Projects extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Projects - over Training Time</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">Home</Link></li>
								<li>Projects</li>
							</ul>
						</div>
					</div>

					<div className="content-block">

						<div className="section-area section-sp1 gallery-bx">
							<div className="container">
								{/* 		<ProjectsContent /> */}
								<h3>Coming Soon</h3>
							</div>
						</div>

					</div>

				</div>

				<Footer />

			</>
		);
	}
}

export default Projects;