
export const listofData = [
	{ 
		Id      :1,
		Name    :"Vishwajeet Kumar Singl",
		Salary  :"6Lpa",
		Company :"German Town",
		LinkedIn:"https://www.linkedin.com/in/vishwajeet-kumar-683761229",
	 },
	 
	 { 
		Id      :2,
		Name    :"Alfaz Baig",
		Salary  :"6Lpa",
		Company :"German Town",
		LinkedIn:"https://www.linkedin.com/in/alfazbaig13",
	 },
	  
	 {  
		 Id     :3,
		Name    :"V.Rakshith",
		Salary  :"4.5Lpa",
		Company :"Acharya Institutes",
		LinkedIn:"http://www.linkedin.com/in/rakshith-raghu-64379a183",
	 },
	 
	 {
		Id      :4,
		Name    :"Mayank Mohan Dixit",
		Salary  :"6Lpa",
		Company :"Ritzyware",
		LinkedIn:""
	 },
	 
	 {
		Id      :5,
		Name    :"Sarfaraz Khan",
		Salary  :"8Lpa",
		Company :"Zapcom",
		LinkedIn:""
	 },
	 
	 {
		Id      :6,
		Name    :"Vinay Reddy",
		Salary  :"5Lpa",
		Company :"ConnectmM",
		LinkedIn:"https://www.linkedin.com/in/bijjam-vinay-63a65b172",
	 },
	 
	 { 
		Id      :7,
		Name    :"Venkat Garapati",
		Salary  :"3.5Lpa",
		Company :"TCS",
		LinkedIn:""
	 },
	 
	 {
		Id      :8,
		Name    :"Sravani",
		Salary  :"3.5Lpa",
		Company :"TCS",
		LinkedIn:"https://www.linkedin.com/in/mungani-sravani-3b5022221",
	 },
	 
	 {
		Id      :9,
		Name    :"T.Srinivas",
		Salary  :"3.5Lpa",
		Company :"TCS",
		LinkedIn:"https://www.linkedin.com/in/thumati-srinivas-3b6325216",
	 },
	 
	 {
		Id      :10,
		Name    :"Sangeetha",
		Salary  :"6Lpa",
		Company :"German Town",
		LinkedIn:""
	 },
	 
	 {
		Id      :11,
		Name    :"Banu Prasad",
		Salary  :"3.5Lpa",
		Company :"TCS",
		LinkedIn:""
	 },
	 
	 {
		Id      :12,
		Name    :"Vineetha",
		Salary  :"3.5Lpa",
		Company :"Infosys",
		LinkedIn:"https://www.linkedin.com/jobs/application-settings?li_theme=dark",
	 },
	 
	 {
		Id      :13,
		Name    :"Rubiya",
		Salary  :"4Lpa",
		Company :"Vaeso",
		LinkedIn:"https://www.linkedin.com/in/rubiya-jugul-39910b1b6",
	 },
	 
	 {
		Id      :14,
		Name    :"Srinivas",
		Salary  :"7Lpa",
		Company :"Wissen Technology",
		LinkedIn:"https://www.linkedin.com/in/mangalapudi-srinivasulu-10076017b",
	 },
	 
	 {
		Id      :15,
		Name    :"Sai Kiran",
		Salary  :"3.5Lpa",
		Company :"BASILMIND",
		LinkedIn:"https://www.linkedin.com/in/sai-kiran-j-10bb601b3",
	 }, 
	 
	 
	 {
		Id      :16,
		Name    :"Swathi",
		Salary  :"7.5Lpa",
		Company :"US Based Startup",
		LinkedIn:""
	 },
	 
	 {
		Id      :17,
		Name    :"Eswar",
		Salary  :"8Lpa",
		Company :"HCL",
		LinkedIn:"",
	 },
	 
	 {
		Id      :18,
		Name    :"Teja",
		Salary  :"8Lpa",
		Company :"Virtusa",
		LinkedIn:"https://www.linkedin.com/public-profile/settings?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_self_edit_contact-info%3BGdTSnqcNQ0CVxzXjAZmscA%3D%3D",
	 },
	 
	 {
		Id      :19,
		Name    :"Amit",
		Salary  :"3Lpa",
		Company :"Quik Ride",
		LinkedIn:""
	 },
	 
	 {
		Id      :20,
		Name    :"Prthiba",
		Salary  :"3.5Lpa",
		Company :"Anakin",
		LinkedIn:""
	 },
	 
	 { 
		Id      :21,
		Name    :"B.Vinay",
		Salary  :"4Lpa",
		Company :"HCL",
		LinkedIn:""
	 },
	 
	 {
		Id      :22,
		Name    :"Ratheesh",
		Salary  :"3.5Lpa",
		Company :"TCS",
		LinkedIn:""
	 },
	 
	 {
		Id      :23,
		Name    :"Mamatha",
		Salary  :"8Lpa",
		Company :"Photon",
		LinkedIn:""
	 },
	 
	 {
		Id      :24,
		Name    :"Pooja Patra",
		Salary  :"6.5Lpa",
		Company :"Tech Mahindra",
		LinkedIn:""
	 },
	 
	 {
		Id      :25,
		Name    :"Girija",
		Salary  :"3Lpa",
		Company :"JK Techsoft",
		LinkedIn:""
	 },
	 
	 {
		Id      :26,
		Name    :"Arvind",
		Salary  :"6Lpa",
		Company :"Accenture",
		LinkedIn:""
	 },
	 
	 {
		Id      :27,
		Name    :"Karthik",
		Salary  :"3Lpa",
		Company :"Start Up",
		LinkedIn:""
	 },
	 
	 {
		Id      :28,
		Name    :"Dinesh Gorla",
		Salary  :"3Lpa",
		Company :"Wave Labs",
		LinkedIn:"http://www.linkedin.com/in/GorlaDinesh1997",
	 },
	 
	 {
		Id      :29,
		Name    :"Lithesh",
		Salary  :"3Lpa",
		Company :"Wave Labs",
		LinkedIn:"https://www.linkedin.com/in/lithesh-kumar-818589200",
	 },
	 
	 {
		Id      :30,
		Name    :"Tejaswini",
		Salary  :"4Lpa",
		Company :"Accenture",
		LinkedIn:""
	 },
	 
	 {
		Id      :31,
		Name    :"Abhishek",
		Salary  :"4Lpa",
		Company :"Infosys",
		LinkedIn:""
	 },
	 
	 { 
		Id      :32,
		Name    :"Shashi Kumar",
		Salary  :"3.5Lpa",
		Company :"Start Up",
		LinkedIn:"http://linkedin.com/in/shashi-kumar-4925a920b",
	 },
	 
	 {
		Id      :33,
		Name    :"Siddiq",
		Salary  :"4Lpa",
		Company :"Start UP",
		LinkedIn:""
	 },
	 
	 {
		Id      :34,
		Name    :"Ashish Verma",
		Salary  :"5.5Lpa",
		Company :"CMMI-IEVEL3-Banglore",
		LinkedIn:"https://www.linkedin.com/in/ashish-verma-3bb68573",
	 },
	 
	 {
		Id      :35,
		Name    :"K.Chiranjeevi",
		Salary  :"3.5Lpa",
		Company :"Start Up",
		LinkedIn:"https://www.linkedin.com/in/chiranjeevi-kosanam-454805169/",
	 },
	 
	 {
		
		Id      :36,
		Name    :"Nagendra Reddy",
		Salary  :"6Lpa",
		Company :"Pofitec.com",
		LinkedIn:""
	 },
	 
	 {
		
		Id      :37,
		Name    :"Yaman-Gujarath",
		Salary  :"6Lpa",
		Company :"Tech tic",
		LinkedIn:""
	 },
	 
	 {
		
		Id      :38,
		Name    :"Jai Kumar",
		Salary  :"6Lpa",
		Company :"Aspire Software",
		LinkedIn:"https://www.linkedin.com/in/jay-baria-609307171",
	 },
	 
	 {
		
		Id      :39,
		Name    :"S.Surendra",
		Salary  :"7.5Lpa",
		Company :"CMMI-IEVEL3-Banglore",
		LinkedIn:"https://www.linkedin.com/in/surender-reddy-1137618a/",
	 },
	 
	 {
		Id      :40,
		Name    :"R.Shiva",
		Salary  :"6Lpa",
		Company :"CMMI-LEVEL33-Banglore",
		LinkedIn:""
	 },
	 
	 {
		Id      :41,
		Name    :"Pavani",
		Salary  :"4Lpa",
		Company :"TCS",
		LinkedIn:""
	 },
	 
	 {
		Id      :42,
		Name    :"Bheem",
		Salary  :"4Lpa",
		Company :"CMMI-LEVEL33-Banglore",
		LinkedIn:""
	 },
	 
	 {
		Id      :43,
		Name    :"Ravi",
		Salary  :"6Lpa",
		Company :"Wave Labs",
		LinkedIn:""
	 },
	 
	 {
		Id      :44,
		Name    :"Sreenivas",
		Salary  :"4Lpa",
		Company :"Doc-Startup",
		LinkedIn:""
	 },
	 
	 {
		
		Id      :45,
		Name    :"KK Reddy",
		Salary  :"8Lpa",
		Company :"EcoLab",
		LinkedIn:""
	 },
		
	 {
		Id      :46,
		Name    :"Yogesh",
		Salary  :"6Lpa",
		Company :"CMMI-LEVEL33-Banglore",
		LinkedIn:""
	 },
	 
	 {
		
		Id      :47,
		Name    :"Pooja Rai",
		Salary  :"4Lpa",
		Company :"Genpact",
		LinkedIn:""
	 },
	 
	 {
		
		Id      :48,
		Name    :"Chitanya ",
		Salary  :"7Lpa",
		Company :"CTS",
		LinkedIn:""
	 },
	 
	 {
		
		Id      :49,
		Name    :"M.Kiran",
		Salary  :"7.5Lpa",
		Company :"IBM",
		LinkedIn:""
	 },
	 
	 {
		
		Id      :50,
		Name    :"Vinayaka",
		Salary  :"6.5Lpa",
		Company :"Collebra",
		LinkedIn:""
	 },
	 
	 {
		
		Id      :51,
		Name    :"Prasad",
		Salary  :"6Lpa",
		Company :"Light Matrix",
		LinkedIn:""
	 },
	 
	 {
		 Id     :52,
		Name    :"M.Sai Kumar",
		Salary  :"3.5Lpa",
		Company :"TCS",
		LinkedIn:"https://www.linkedin.com/in/modagala-saikumar-5219b7228",
	 },
	 
	 {
		 Id     :53,
		Name    :"Kavitha",
		Salary  :"3.6Lpa",
		Company :"TCS",
		LinkedIn:""
	 },
	 
	 {
		Id      :54,
		Name    :"Laxmi Pathi",
		Salary  :"3Lpa",
		Company :"Cognizant",
		LinkedIn:""
	 },
	 
	 {
		Id      :55,
		Name    :"Harshitha",
		Salary  :"3Lpa",
		Company :"Infosys",
		LinkedIn:"http://www.linkedin.com/in/aasritha-r-513127231",
	 },
	 
	 {
		Id      :56,
		Name    :"Nishanth Gowtham",
		Salary  :"3.6Lpa",
		Company :"Ideya Labs",
		LinkedIn:""
	 },
	 
	 {
		Id      :57,
		Name    :"Aman",
		Salary  :"3.6Lpa",
		Company :"Ideya Labs",
		LinkedIn:""
	 },
	 
	 {
		Id      :58,
		Name    :"Darshan",
		Salary  :"3.6Lpa",
		Company :"Ideya Labs",
		LinkedIn:""
	 },
	 
	 {
		Id      :59,
		Name    :"Prakash",
		Salary  :"3.5Lpa",
		Company :"TCS",
		LinkedIn:""
	 },
	 
	 {
		Id      :60,
		Name    :"Ragava Pallu",
		Salary  :"3Lpa",
		Company :"Cognizant",
		LinkedIn:""
	 },
	 
	 {
		Id      :61,
		Name    :"Sanjay",
		Salary  :"3.6Lpa",
		Company :"Infosys",
		LinkedIn:"https://www.linkedin.com/in/jay-baria-609307171",
	 },
	 
	 {
		Id      :62,
		Name    :"Mohan Vinayak",
		Salary  :"3.6Lpa",
		Company :"Infosys",
		LinkedIn:""
	 },
	 
	 {
		Id      :63,
		Name    :"B.Vijay",
		Salary  :"3.5Lpa",
		Company :"DATAMATICS",
		LinkedIn:""
	 },
	 
	 {
		Id      :64,
		Name    :"Sahana",
		Salary  :"3.5Lpa",
		Company :"TATA Communications",
		LinkedIn:"https://www.linkedin.com/in/sahana-n-768722226",
	 },
	 
	 {
		Id      :65,
		Name    :"Abhishek",
		Salary  :"3.5Lpa",
		Company :"Wipro",
		LinkedIn:""
	 },
	 
	 {
		Id      :66,
		Name    :"Nithin",
		Salary  :"3.6Lpa",
		Company :"TCS",
		LinkedIn:""
	 },
	 
	 {
		Id      :67,
		Name    :"Sujatha",
		Salary  :"9Lpa",
		Company :"Robosoft Technologies",
		LinkedIn:""
	 },
	 
	 {
		Id      :68,
		Name    :"Kalyan",
		Salary  :"3Lpa",
		Company :"Infosys",
		LinkedIn:"https://www.linkedin.com/in/venkata-kalyan-7bb084148/",
	 },
	 
	 {
		Id      :69,
		Name    :"Vasantha",
		Salary  :"3Lpa",
		Company :"Atos",
		LinkedIn:""
	 },
	 
	 {
		Id      :70,
		Name    :"Aakash",
		Salary  :"3Lpa",
		Company :"Panorbit",
		LinkedIn:""
	 },
	 
	 {
		Id      :71,
		Name    :"Preethi",
		Salary  :"3Lpa",
		Company :"Infosys",
		LinkedIn:""
	 },
	 
	 {
		 Id     :72,
		Name    :"Usha",
		Salary  :"6Lpa",
		Company :"Tecnotree",
		LinkedIn:""
	 },
	 
	 {
		 Id     :73,
		Name    :"Hemanth Raju",
		Salary  :"3.5Lpa",
		Company :"Leansys, mindtree",
		LinkedIn:""
	 },
	 
	 {
		Id      :74,
		Name    :"kiran kumar reddy",
		Salary  :"3.5Lpa",
		Company :"Ideya Labs",
		LinkedIn:""
	 }, 
	 
	 {
		 Id     :75,
		Name    :"S.Lakshmi prasanna naidu",
		Salary  :"3.7Lpa",
		Company :"Ideya Labs",
		LinkedIn:""
	 }, 
	 
	 {
		
		 Id     :76,
		Name    :"Kanishka",
		Salary  :"6Lpa",
		Company :"Germantown",
		LinkedIn:""
	 }, 
	 
	 {
		Id      :77,
		Name    :"Ravi",
		Salary  :"6Lpa",
		Company :"Germantown",
		LinkedIn:""
	 },
	 
	 {
		Id      :78,
		Name    :"Sai Kumar",
		Salary  :"9Lpa",
		Company :"Tech M",
		LinkedIn:""
	 },
	 
	 {
		
		Id      :79,
		Name    :"Suneetha",
		Salary  :"3Lpa",
		Company :"Infosys",
		LinkedIn:""
	 },
	 
	 {
	  
		Id      :80,
		Name    :"upendra",
		Salary  :"3.5Lpa",
		Company :"TCS",
		LinkedIn:"https://www.linkedin.com/in/upendra98",
	 },
	 
	 {
	 
		Id      :81,
		Name    :"Hemanth Raju",
		Salary  :"3.8Lpa",
		Company :"Autointelli",
		LinkedIn:"http://www.linkedin.com/in/nallurihemanthraju",
	 },
];
