import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import pythonFullStack_Image from '../../images/home/pythonfullstack.jpg';
import fullStack_Image from '../../images/home/fullstack.jpg';
import javaFullStack from '../../images/home/javafullstack.jpg';

class ThreeCourses extends Component {
	render() {
		return (
			<>
				<div className="section-area section-sp1 service-info-bx">
					<div className="container">
						<div className="row">
							<div className="col-md-12 heading-bx left">
								<h2 className="title-head">Why Choose <span> Pro Stack Academy</span></h2>
								<p>Providing Best Training and 100% Placement's on Full Stack.</p>
							</div>
						</div>
						<div className="row justify-content-center">
							<div className="col-lg-4 col-md-6 col-sm-12">
								<div className="service-bx m-b30">
									<div className="action-box">
										<img src={pythonFullStack_Image} alt="Python Full Stack Training" />
									</div>
									<div className="info-bx">
										<h4 className="m-b10"><Link to="/python-full-stack-developer">Python Full Stack</Link></h4>
										<p className="m-b20">A Python Full-Stack developer is a combination of both front-end and back-end skills. Developers are capable of developing a complete web application.</p>
										<Link to="/python-full-stack-developer" className="btn radius">View More</Link>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12">
								<div className="service-bx m-b30">
									<div className="action-box">
										<img src={fullStack_Image} alt="Full Stack Developer" />
									</div>
									<div className="info-bx">
										<h4 className="m-b10"><Link to="/full-stack-developer">Full Stack - MERN</Link></h4>
										<p className="m-b20">Learn Full Stack Web Developer with JavaScript, ES6 , React JS and Node JS with ample Demo Application, Projects for hand-on practice.</p>
										<Link to="/full-stack-developer" className="btn radius">View More</Link>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-12">
								<div className="service-bx m-b30">
									<div className="action-box">
										<img src={javaFullStack} alt="Java Full Stack Training" />
									</div>
									<div className="info-bx">
										<h4 className="m-b10"><Link to="/java-full-stack-developer">Java Full Stack</Link></h4>
										<p className="m-b20">Learn and Expertise on Java Fulll Stack Developer with React JS, Java, Java8 Feature's and Spring Boot Framework with Real-time Projects.</p>
										<Link to="/java-full-stack-developer" className="btn radius">View More</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default ThreeCourses;