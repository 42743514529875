import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// Elements
import BackToTop from './elements/Back-Top';
import Successdetails from './elements/Successdetails';
import PageScrollTop from './elements/Page-Scroll-Top';

// Home Pages
import Index from './pages/Index';


// About Us
import HireDevelopers from './pages/HireDevelopers'
import Placements from './pages/Placements'
// import PlacementList from './pages/PlacementList'
import PlacementList from './PlacementDetails/PlacementList'

// Events
import Events from './pages/Events';
import EventsDetails from './pages/EventsDetails';

// Faq
import Faq1 from './pages/Faq-1';
import QA from './pages/InterviewQA'
import Faq2 from './pages/Faq-2';

// Other Pages
import Projects from './pages/Projects';
import Portfolio from './pages/Portfolio';
import Profile from './pages/Profile';
import Membership from './pages/Membership';
import Error404 from './pages/Error404';
import Register from './pages/Register';
import Login from './pages/Login';
import ForgetPassword from './pages/Forget-Password';

// Courses
import Courses from './pages/Courses';
import CoursesDetails from './pages/CoursesDetails';
import JavaFullStack from './pages/Java-Full-Stack-Developer'
import PythonFullStack from './pages/Python-Full-Stack-Developer'
import FullStack from './pages/Full-Stack-Developer'
import ReactTraining from './pages/ReactTraining'
import PythonTraiing from './pages/PythonTraining'

// Blog Pages
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';

// Contact Us
import Contact from './pages/Contact';
import Practice from './pages/Practice';
import JavaEditor from './pages/PracticeEditors/JavaEditor';
import PythonEditor from './pages/PracticeEditors/PythonEditor';
import HtmlEditor from './pages/PracticeEditors/HtmlEditor';

const Markup = () => {
	return (
		<>
			<BrowserRouter>
				{/* <BrowserRouter basename={'/react/'}> */}
				<Switch>

					{/* Home Pages */}
					<Route path='/' exact component={Index} />


					{/* About Us */}
					<Route path='/hire-developers' exact component={HireDevelopers} />
					<Route path='/placements' exact component={Placements} />
					<Route path='/placement-list' exact component={PlacementList} />

					{/* Events */}
					<Route path='/event' exact component={Events} />
					<Route path='/events-details' exact component={EventsDetails} />

					{/* Faq */}
					<Route path='/qa' exact component={QA} />


					{/* Other Pages */}
					<Route path='/success-details' exact component={Successdetails} />
					<Route path='/projects' exact component={Projects} />
					<Route path='/portfolio' exact component={Portfolio} />
					<Route path='/profile' exact component={Profile} />
					<Route path='/membership' exact component={Membership} />
					<Route path='/error-404' exact component={Error404} />
					<Route path='/register' exact component={Register} />
					<Route path='/login' exact component={Login} />
					<Route path='/forget-password' exact component={ForgetPassword} />

					{/* Courses */}
					<Route path='/courses' exact component={Courses} />
					<Route path='/courses-details' exact component={CoursesDetails} />
					<Route path='/java-full-stack-developer' exact component={JavaFullStack} />
					<Route path='/python-full-stack-developer' exact component={PythonFullStack} />
					<Route path='/full-stack-developer' exact component={FullStack} />
					<Route path='/python-training' exact component={PythonTraiing} />
					<Route path='/reactjs-training' exact component={ReactTraining} />

					{/* Blog Pages */}
					{/* <Route path='/blog' exact component={Blog} /> */}

					<Route path='/blog-details' exact component={BlogDetails} />

					{/* Contact Us */}
					<Route path='/contact' exact component={Contact} />
					<Route path='/practice' exact component={Practice} />
					{/* Editors */}
					<Route path='/java-editor' exact component={JavaEditor} />
					<Route path='/python-editor' exact component={PythonEditor} />
					<Route path='/html-editor' exact component={HtmlEditor} />

				</Switch>

				<PageScrollTop />

			</BrowserRouter>

			<BackToTop />

		</>

	);
}

export default Markup;