import React, { useEffect } from 'react';
import Markup from './markup/markup';
import ContactHomePopup from './markup/popUp/ContactForm/ContactHomePopup';
import { useStateValue } from "./store/state";
import { useHistory } from 'react-router-dom';
import ContactSuccessPopup from './markup/popUp/ContactSuccess/ContactSuccessPopup';
import ContactErrorPopup from './markup/popUp/ContactSuccess/ContactErrorPopup';
import LoadPopup from './markup/popUp/Loader/LoadPopup';
// Plugins Stylesheet
import 'bootstrap/dist/css/bootstrap.min.css';

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Modal Video
import 'react-modal-video/css/modal-video.min.css';

// StyleSheet
import './css/typography.css';
import './css/shortcodes/shortcodes.css';
import './css/style.css';
import './css/color/color-1.css';

// Fonts
import './vendors/fontawesome/css/font-awesome.min.css';
import './vendors/flaticon/flaticon.css';
import './vendors/line-awesome/css/line-awesome.min.css';
import './vendors/themify/themify-icons.css';
// Whatsaap image
import WhatsaapImage from './images/whatsapp_icon.png';

function App() {
	const [{ home_popup, showLoader, showSuccessPopup, showErrorPopup }, dispatch] = useStateValue();
	const history = useHistory();
	const { location } = history;
	return (<>
		<div className="page-wraper">
			<Markup />
		</div>

		{
			home_popup && (
				location.pathname === "/" ||
				location.pathname === "/java-full-stack-developer" ||
				location.pathname === "/python-full-stack-developer" ||
				location.pathname === "/full-stack-developer" ||
				location.pathname === "/courses"
			) && <ContactHomePopup />
		}
		{
			home_popup && <ContactHomePopup />
		}
		{
			showSuccessPopup && <ContactSuccessPopup />
		}
		{
			showErrorPopup && <ContactErrorPopup />
		}
		{
			showLoader && <LoadPopup />
		}

		<div id="whatsapp-button" class="floating-button">
			<a href="https://wa.me/9591619191" target="_blank">
				<img src={WhatsaapImage} alt="WhatsApp" />
			</a>
		</div>

	</>);
}

export default App;
