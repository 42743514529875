import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo-white.png';
import online from '../../../images/online.png'
class Footer extends Component {
	render() {
		return (
			<>
				<footer>
					<div className="footer-top">
						<div className="pt-exebar">
							<div className="container">
								<div className="d-flex align-items-stretch">
									<div className="pt-logo mr-auto">
										<Link to="/"><img src={logo} alt="" /></Link>
									</div>
									<div className="pt-social-link">
										<ul className="list-inline m-a0">
											<li><Link to={{ pathname: "https://www.facebook.com/prostackacademyindia" }} target="_blank" className="btn-link"><i className="fa fa-facebook"></i></Link></li>
											<li><Link to={{ pathname: "https://twitter.com/prostackacademy" }} target="_blank" className="btn-link"><i className="fa fa-twitter"></i></Link></li>
											<li><Link to={{ pathname: "https://www.linkedin.com/company/prostackacademy/" }} target="_blank" className="btn-link"><i className="fa fa-linkedin"></i></Link></li>

										</ul>
									</div>
									<div className="pt-btn-join">
										<Link to="/contact" className="btn">Join Now</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-12 col-sm-12 footer-col-4">
									<div className="widget">
										<h6 className="footer-title">About Pro Stack</h6>
										<p className="text-capitalize m-b20">Best Full Stack Web Developer Training Institute in Bangalore, Providing Training & 100 % Placements.</p>
										<p className="text-capitalize m-b20">
											<b>Address:&nbsp;</b>
											<a href="https://goo.gl/maps/J6M1rUsbkcoET9xX7" style={{ color: "#b0b0b0" }}>
												RJ Garden, #96, Ground Floor, Chinnappanahalli, Marathahalli, Bengaluru, Karnataka 560037.
											</a>
										</p>
									</div>
								</div>
								<div className="col-12 col-lg-5 col-md-7 col-sm-12">
									<div className="row">
										<div className="col-4 col-lg-4 col-md-4 col-sm-4">
											<div className="widget footer_widget">
												<h6 className="footer-title">Pro Stack</h6>
												<ul>
													{/* <li><Link to="/projects">Project</Link></li> */}
													<li><Link to="/practice">Practice</Link></li>
													<li><Link to="/placement-list">Placements</Link></li>
													<li><Link to="/hire-developers">Hire Developers</Link></li>
													<li><Link to="/profile">Profile</Link></li>
												</ul>
											</div>
										</div>
										<div className="col-4 col-lg-4 col-md-4 col-sm-4">
											<div className="widget footer_widget">
												<h6 className="footer-title">Get In Touch</h6>
												<ul>
													<li><Link to="/contact">Contact</Link></li>
													{/* <li><Link to="/blog">Blog</Link></li> */}
													<li><Link to="/">Resources</Link></li>
													<li><Link to={{ pathname: "https://github.com/narasimhareddyprostack " }} target="_blank">Git Hub</Link></li>
												</ul>
											</div>
										</div>
										<div className="col-4 col-lg-4 col-md-4 col-sm-4">
											<div className="widget footer_widget">
												<h6 className="footer-title">Courses</h6>
												<ul>
													<li><Link to="/courses">All Courses</Link></li>
													<li><Link to="/java-full-stack-developer">Java Full Stack</Link></li>
													<li><Link to="/python-full-stack-developer">Python Full Stack</Link></li>
													<li><Link to="/full-stack-developer">Full Stack- MERN</Link></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-lg-3 col-md-5 col-sm-12 footer-col-4">
									<div className="widget widget_gallery gallery-grid-4">
										<h5 className="footer-title">Online Training</h5>

										<img src={online} alt="online Training" />
										<Link to="/"><h6 className="footer-title">Payment Info</h6></Link>
										<p><b>GPay / PhonePe / Paytm </b></p>
										<p>9591 61 9191</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 text-center"> © 2024 <span className="text-white">Pro Stack Academy</span> <b>|</b> All Rights Reserved.</div>
							</div>
						</div>
					</div>
				</footer>

			</>
		);
	}
}

export default Footer;
