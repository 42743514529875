import React from 'react';
import './Style.scss';
const ContactSuccessPopup = () => {
    return (<>
        <div className='contact_form_success_messge'>
            <div className='success_block'>
                <div className='success_messge'>
                    <p>Form sent successfully!</p>
                </div>
            </div>
        </div>
    </>)
}

export default ContactSuccessPopup;