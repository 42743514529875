import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header/Header1";
import Footer from "../layout/footer/Footer";

// Images
import bannerImg from '../../images/banner/banner3.jpg';
import adv from '../../images/adv/adv.jpg';
import blogRecentPic1 from '../../images/blog/recent-blog/pic1.jpg';
import blogRecentPic3 from '../../images/blog/recent-blog/pic3.jpg';
import coursesPic1 from '../../images/courses/pic1.jpg';
import coursesPic2 from '../../images/courses/pic2.jpg';
import coursesPic3 from '../../images/courses/pic3.jpg';
import coursesPic4 from '../../images/courses/pic4.jpg';
import coursesPic5 from '../../images/courses/pic5.jpg';
import coursesPic6 from '../../images/courses/pic6.jpg';
import coursesPic7 from '../../images/courses/pic7.jpg';
import coursesPic8 from '../../images/courses/pic8.jpg';
import coursesPic9 from '../../images/courses/pic9.jpg';

const content = [
	{
		thumb: coursesPic1,
		title: "Python Full Stack",
		tag: "Programming",
		review: 5,
		priceDel: 120,
		price: 190,
		link: "python-full-stack-developer"
	},
	{
		thumb: coursesPic2,
		title: "Full Stack Developer",
		tag: "Programming",
		review: 5,
		priceDel: 180,
		price: 150,
		link: "full-stack-developer"
	},
	{
		thumb: coursesPic3,
		title: "Python Training",
		tag: "Coding",
		review: 5,
		priceDel: 520,
		price: 234,
		link: "python-training"
	},
	{
		thumb: coursesPic4,
		title: "React JS Training",
		tag: "Front-end Developer",
		review: 5,
		priceDel: 320,
		price: 260,
		link: "reactjs-training"
	},
	{
		thumb: coursesPic5,
		title: "Java Full Stack",
		tag: "Coding",
		review: 5,
		priceDel: 120,
		price: 260,
		link: "java-full-stack-developer"
	},

]
class Courses extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Our Courses</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">Home</Link></li>
								<li>Our Courses</li>
							</ul>
						</div>
					</div>

					<div className="content-block">

						<div className="section-area section-sp1">
							<div className="container">
								<div className="row">
									<div className="col-lg-3 col-md-4 col-sm-12">
										<div className="widget widget_archive">
											<h5 className="widget-title style-1">All Courses</h5>
											<ul>

												<li className="active"><Link to="/python-training">IT & Software</Link></li>
												<li><Link to="/java-full-stack-developer">Coding</Link></li>
												<li><Link to="/python-training">Programming Language</Link></li>
												<li><Link to="/full-stack-developer">Full Stack</Link></li>
											</ul>
										</div>

										<div className="widget">
											<Link to="/contact"><img src={adv} alt="" /></Link>
										</div>
									</div>
									<div className="col-lg-9 col-md-8 col-sm-12">
										<div className="row">
											{content.map((item) => (
												<div className="col-md-6 col-lg-4 col-sm-6 m-b30">
													<div className="cours-bx">
														<div className="action-box">
															<img src={item.thumb} alt="" />
															<Link to={item.link} className="btn">Read More</Link>
														</div>
														<div className="info-bx">
															<span>{item.tag}</span>
															<h6><Link to={item.link}>{item.title}</Link></h6>
														</div>
														<div className="cours-more-info">
															<div className="review">
																<span>{item.review} Review</span>
																<ul className="cours-star">
																	<li className="active"><i className="fa fa-star"></i></li>
																	<li className="active"><i className="fa fa-star"></i></li>
																	<li className="active"><i className="fa fa-star"></i></li>
																	<li className="active" ><i className="fa fa-star"></i></li>
																	<li className="active"><i className="fa fa-star"></i></li>
																</ul>
															</div>
															<div className="price">
																{/* <del>${item.PriceDel}</del>
																<h5>${item.Price}</h5> */}
															</div>
														</div>
													</div>
												</div>
											))}
											<div className="col-lg-12 m-b20">
												<div className="pagination-bx rounded-sm gray clearfix">
													<ul className="pagination">
														<li className="previous"><Link to="#"><i className="ti-arrow-left"></i> Prev</Link></li>
														<li className="active"><Link to="#">1</Link></li>
														<li><Link to="#">2</Link></li>
														<li><Link to="#">3</Link></li>
														<li className="next"><Link to="#">Next <i className="ti-arrow-right"></i></Link></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>

				<Footer />

			</>
		);
	}
}

export default Courses;