import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header/Header1";
import Footer from "../layout/footer/Footer";

// Images
import bannerImg from '../../images/banner/banner1.jpg';
import "./Practice.scss";

class Practice extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content practice-container">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Practice - over Training Time</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">Home</Link></li>
								<li>Practice</li>
							</ul>
						</div>
					</div>

					<div className="content-block">

						<div className="section-area section-sp1 gallery-bx">
							<div className="container">
								<h5 className='m-b10'>Choose your Editor to Code</h5>
									<table>
										<thead>
											<tr>
												<th>Java</th>
												<th>Python</th>
												<th>HTML CSS JS</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><Link to="/java-editor">Java Editor</Link></td>
												<td><Link to="/python-editor">Python Editor</Link></td>
												<td><Link to="/html-editor">HTML Editor</Link></td>
											</tr>
										</tbody>
									</table>
							</div>
						</div>

					</div>
				</div>

				<Footer />

			</>
		);
	}
}

export default Practice;