export const initialState = {
  home_popup:false,
  showLoader:false,
  showSuccessPopup:false,
  showErrorPopup:false,
};

const reducer = (state, action)=>{
  switch(action.type){
      case "HOME__POPUP":
      return {
          ...state,
          home_popup: action.value
      };
      case "SHOW__LOADER":
      return {
      ...state,
      showLoader: action.value
      };
      case "SHOW__SUCCESS__POPUP":
      return {
        ...state,
        showSuccessPopup: action.value
      };
      case "SHOW__ERROR__POPUP":
      return {
        ...state,
        showErrorPopup: action.value
      };
      default :
          return state;
  }   
}

export default reducer;