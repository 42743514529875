import React from 'react';
import './Style.scss';
const LoadPopup = () => {
  return (<>
    <div className='loader_sec'>
        <div className='loader_block'>
            <div className='loader_text'>Sending...</div>
        </div>
    </div>
  </>)
}

export default LoadPopup;