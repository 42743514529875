import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Images
import coursesPic1 from '../../images/home/python.jpg';
import coursesPic2 from '../../images/home/mern.jpg';
import coursesPic3 from '../../images/home/corepython.jpg';
import coursesPic4 from '../../images/home/react.jpg';
import coursesPic5 from '../../images/home/java.jpg';

// Content
const content = [
	{
		thumb: coursesPic1,
		title: "Python Full Stack",
		tag: "Programming",
		review: 5,
		priceDel: 120,
		price: 190,
		link: "python-full-stack-developer"
	},
	{
		thumb: coursesPic2,
		title: "Full Stack Developer",
		tag: "Programming",
		review: 5,
		priceDel: 180,
		price: 150,
		link: "full-stack-developer"
	},
	{
		thumb: coursesPic3,
		title: "Python Training",
		tag: "Coding",
		review: 5,
		priceDel: 520,
		price: 234,
		link: "python-training"
	},
	{
		thumb: coursesPic4,
		title: "React JS Training",
		tag: "Front-end Developer",
		review: 5,
		priceDel: 320,
		price: 260,
		link: "reactjs-training"
	},
	{
		thumb: coursesPic5,
		title: "Java Full Stack",
		tag: "Coding",
		review: 5,
		priceDel: 120,
		price: 260,
		link: "java-full-stack-developer"
	},

]

class PopularCoursesSlider extends Component {
	render() {

		const settings = {
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 360,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};

		return (
			<>
				<div className="section-area section-sp1 bg-gray popular-courses-bx">
					<div className="container">
						<div className="row">
							<div className="col-md-12 heading-bx left">
								<h2 className="title-head">High Demand <span>Courses</span></h2>
								<p>High Paying skills:Top in-demand skill program for future.</p>
							</div>
						</div>
						<Slider {...settings} className="courses-carousel slick-slider owl-btn-1">
							{content.map((item) => (
								<div className="slider-item">
									<div className="cours-bx">
										<div className="action-box">
											<img src={item.thumb} alt="" />
											<Link to={item.link} className="btn">Read More</Link>
										</div>
										<div className="info-bx">
											<span>{item.tag}</span>
											<h6><Link to={item.link}>{item.title}</Link></h6>
										</div>
										<div className="cours-more-info">
											<div className="review">
												<span>{item.review} Review</span>
												<ul className="cours-star">
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
												</ul>
											</div>
											<div className="price">
												{/* <del>${item.priceDel}</del>
												<h5>${item.price}</h5> */}
												<Link to={item.link} className="btn">Contact Us</Link>
											</div>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</>
		);
	}
}

export default PopularCoursesSlider;