import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../../layout/header/Header1";
import Footer from "../../layout/footer/Footer";

// Images
import bannerImg from '../../../images/banner/banner1.jpg';
import "../Practice.scss";

class HtmlEditor extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content java-editor-container">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">HTML/CSS/JavaScript Editor</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">Home</Link></li>
								<li><Link to="/practice">Practice</Link></li>
								<li>HTML/CSS/JavaScript</li>
							</ul>
						</div>
					</div>

					<div className="content-block">

						<div className="section-area section-sp1 gallery-bx">
							<div className="container">
							<iframe src="https://trinket.io/embed/html/c91a95a53b" width="100%" height="600" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
							</div>
						</div>

					</div>
				</div>

				<Footer />

			</>
		);
	}
}

export default HtmlEditor