import React, { useState } from 'react';
import './Style.scss';
import emailjs from '@emailjs/browser';
import { isMobile, isEmailValid } from '../../../Utilities';
import { useStateValue } from "../../../store/state";

const ContactHomePopup = () => {
	const [{ }, dispatch] = useStateValue();
    const [formValues, setFormValues] = useState({
        name: "",
        mobile: "",
        email:"",
        course: "",
    })
    const [inputValidation, setInputValidation] = useState({
        name: "",
        mobile: "",
        email:"",
        course: "",
    })

    const sendEmail = (e) => {
    	dispatch({
            type: "SHOW__LOADER",
            value: true
        })
    	emailjs.send( 'service_jork1bl', 'template_lb18gr7', formValues, '4BfcEtT5i93JIVn8w')
    	  .then((res) => {
    		dispatch({
                type: "SHOW__LOADER",
                value: false
            })
    		res?.status===200?dispatch({
                type: "SHOW__SUCCESS__POPUP",
                value: true
            }):dispatch({
                type: "SHOW__SUCCESS__POPUP",
                value: false
            });
            dispatch({
                type: "HOME__POPUP",
                value: false
            })
    		setTimeout(()=>{
    			dispatch({
                    type: "SHOW__SUCCESS__POPUP",
                    value: false
                })
    		},2500)
    	  }, (error) => {
    		  console.log(error.text);
              dispatch({
                type: "SHOW__ERROR__POPUP",
                value: false
              })
    	  });
    };

    const validationHandler = (e) => {
        e.preventDefault();
        let isError = false;
        if (!formValues?.name) {
            setInputValidation((prevState) => ({
                ...prevState,
                name: "Please Enter Name."
            }))
            isError = true
        }
        if(!formValues?.email){
			setInputValidation((prevState)=>({
				...prevState,
				email: "Please Enter Email."
			}))
			isError = true
		} else if (!isEmailValid(formValues?.email)) {
			setInputValidation((prevState)=>({
				...prevState,
				email: "Please Enter Valid Email."
			}))
			isError = true;
		}
        if (!formValues?.mobile) {
            setInputValidation((prevState) => ({
                ...prevState,
                mobile: "Please Enter Phone Number."
            }))
            isError = true
        } else if (!isMobile(formValues?.mobile)) {
            setInputValidation((prevState) => ({
                ...prevState,
                mobile: "Please Enter Only Numbers."
            }))
            isError = true
        }
        if (!formValues?.course) {
            setInputValidation((prevState) => ({
                ...prevState,
                course: "Please Select Program."
            }))
            isError = true
        }

        //clear all and submit data
        if (!isError) {
            setFormValues((prevState) => ({
                ...prevState,
                name: "",
                mobile: "",
                course: "",
            }))
            sendEmail(e)
        }
    }
    return (<>
        <div className='contact_home_modal_container'>
            <div className='contact_home_modal_content'>
                <span className='close_modal' onClick={()=>{
                    dispatch({
                        type: "HOME__POPUP",
                        value: false
                    })
                }}>&#xd7;</span>
                <form className="contact-bx ajax-form">
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                name="name"
                                value={formValues?.name}
                                type="text"
                                placeholder="Your Name"
                                className="form-control valid-character"
                                onChange={(e) => {
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        name: e.target.value
                                    }))
                                    setInputValidation((prevState) => ({
                                        ...prevState,
                                        name: ""
                                    }))
                                }} />
                            {inputValidation?.name && <p className='form-error-message'>{inputValidation?.name}</p>}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                name="mobile"
                                value={formValues?.mobile}
                                type="text"
                                placeholder="Your Phone"
                                className="form-control int-value"
                                onChange={(e) => {
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        mobile: e.target.value
                                    }))
                                    setInputValidation((prevState) => ({
                                        ...prevState,
                                        mobile: ""
                                    }))
                                }} />
                            {inputValidation?.mobile && <p className='form-error-message'>{inputValidation?.mobile}</p>}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                name="email"
                                value={formValues?.email}
                                type="text"
                                placeholder="Your Email"
                                className="form-control int-value"
                                onChange={(e) => {
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        email: e.target.value
                                    }))
                                    setInputValidation((prevState) => ({
                                        ...prevState,
                                        email: ""
                                    }))
                                }} />
                            {inputValidation?.email && <p className='form-error-message'>{inputValidation?.email}</p>}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            {/* <input name="subject" type="text" placeholder="Subject" required className="form-control" /> */}
                            <select
                                class="input-form-select form-control"
                                name="course"
                                value={formValues?.course}
                                onChange={(e) => {
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        course: e.target.value
                                    }))
                                    setInputValidation((prevState) => ({
                                        ...prevState,
                                        course: ""
                                    }))
                                }}>
                                <option selected className='select-options' value="">Select Course</option>
                                <option value="Java Full Stack Developer" className='select-options'>Java Full Stack Developer</option>
                                <option value="Python Full Stack Developer" className='select-options'>Python Full Stack Developer</option>
                                <option value="Full Stack Developer" className='select-options'>Full Stack Developer</option>
                            </select>
                            {inputValidation?.course && <p className='form-error-message'>{inputValidation?.course}</p>}
                        </div>
                    </div>
                    <button
                    name="submit"
                    type="submit"
                    value="Submit"
                    class="btn"
                    onClick={(e) => validationHandler(e)}
                    >
                        Send Message
                    </button>
                </form>
            </div>
        </div>
    </>)
}

export default ContactHomePopup;