import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Images
import Image1 from "../../../images/slider/slide1.jpg"
import Image2 from "../../../images/slider/slide2.jpg"

class Slider1 extends Component {

	render() {

		const settings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
		};

		return (
			<>

				<Slider {...settings} className="tt-slider slider-one slider-sp0">
					<div className="slider-item">
						<div className="slider-thumb ovbl-dark">
							<img src={Image2} alt="" />
						</div>
						<div className="slider-content text-white">
							<div className="container">
								<div className="content-inner">
									<h6 className="sub-title">Welcome To Pro Stack Academy </h6>
									<h2 className="title">Bootcamp Training </h2>
									<h2 className="title">100 % Placement Guarantee.</h2>
									<Link className="btn radius-xl m-r15 button-md white" to="/contact">Contact Us</Link>
									<Link className="btn radius-xl button-md" to="/java-full-stack-developer">Read More</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="slider-thumb ovbl-dark">
							<img src={Image2} alt="" />
						</div>
						<div className="slider-content text-white">
							<div className="container">
								<div className="content-inner">
									<h6 className="sub-title">Full Stack Web Developer Bootcamp Training Program </h6>
									<h2 className="title">Full Stack - MERN</h2>
									<p>Learn Full Stack Web Developer with JavaScript, ES6 , React JS and Node JS with ample Demo Application, Project for hand-on practice.</p>

									<Link className="btn radius-xl button-md" to="/full-stack-developer">Read More</Link>
								</div>
							</div>
						</div>
					</div>
				</Slider>

			</>
		);
	}
}

export default Slider1;
