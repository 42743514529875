import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Contact_Style.scss';
import emailjs from '@emailjs/browser';
import ContactSuccessPopup from '../popUp/ContactSuccess/ContactSuccessPopup';
import LoadPopup from '../popUp/Loader/LoadPopup';
import {isMobile,isEmailValid} from '../../Utilities';
import { useStateValue } from "../../store/state";

// Layout
import Header from "../layout/header/Header1";
import Footer from "../layout/footer/Footer";

// Elements
import ContactInfoCard from '../elements/Contact-Info-Card';

// Images
import bannerImg from '../../images/banner/banner2.jpg';

const Contact = () => {
	const [{ }, dispatch] = useStateValue();
	const [formValues, setFormValues] = useState({
		name:"",
		email:"",
		mobile:"",
		course:"",
		textMessage:""
	})
	const [inputValidation, setInputValidation] = useState({
		name:"",
		email:"",
		mobile:"",
		course:"",
		textMessage:""
	})

	const sendEmail = (e) => {
    	dispatch({
            type: "SHOW__LOADER",
            value: true
        })
    	emailjs.send( 'service_jork1bl', 'template_lb18gr7', formValues, '4BfcEtT5i93JIVn8w')
    	  .then((res) => {
    		dispatch({
                type: "SHOW__LOADER",
                value: false
            })
    		res?.status===200?dispatch({
                type: "SHOW__SUCCESS__POPUP",
                value: true
            }):dispatch({
                type: "SHOW__SUCCESS__POPUP",
                value: false
            });
            dispatch({
                type: "HOME__POPUP",
                value: false
            })
    		setTimeout(()=>{
    			dispatch({
                    type: "SHOW__SUCCESS__POPUP",
                    value: false
                })
    		},2500)
    	  }, (error) => {
    		  console.log(error.text);
              dispatch({
                type: "SHOW__ERROR__POPUP",
                value: false
              })
    	  });
    };

	const validationHandler = (e)=>{
		e.preventDefault();
		let isError = false;
		if(!formValues?.name){
			setInputValidation((prevState)=>({
				...prevState,
				name: "Please Enter Name."
			}))
			isError = true
		}
		if(!formValues?.email){
			setInputValidation((prevState)=>({
				...prevState,
				email: "Please Enter Email."
			}))
			isError = true
		} else if (!isEmailValid(formValues?.email)) {
			setInputValidation((prevState)=>({
				...prevState,
				email: "Please Enter Valid Email."
			}))
			isError = true;
		}
		if(!formValues?.mobile){
			setInputValidation((prevState)=>({
				...prevState,
				mobile: "Please Enter Phone Number."
			}))
			isError = true
		}else if(!isMobile(formValues?.mobile)){
			setInputValidation((prevState)=>({
				...prevState,
				mobile: "Please Enter Only Numbers."
			}))
			isError = true
		}
		if(!formValues?.course){
			setInputValidation((prevState)=>({
				...prevState,
				course: "Please Select Program."
			}))
			isError = true
		}
		// if(!formValues?.textMessage){
		// 	setInputValidation((prevState)=>({
		// 		...prevState,
		// 		textMessage: "Please enter message."
		// 	}))
		// 	isError = true
		// }

		//clear all and submit data
		if(!isError){
			setFormValues((prevState)=>({
				...prevState,
				name:"",
				email:"",
				mobile:"",
				course:"",
				textMessage:""
			}))
			sendEmail(e)
		}
	}
	return (
		<>

			<Header />

			<div className="page-content">

				<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
					<div className="container">
						<div className="page-banner-entry">
							<h1 className="text-white">Contact Us</h1>
						</div>
					</div>
				</div>
				<div className="breadcrumb-row">
					<div className="container">
						<ul className="list-inline">
							<li><Link to="/">Home</Link></li>
							<li>Contact Us</li>
						</ul>
					</div>
				</div>

				<div className="content-block">

					<div className="page-banner contact-page section-sp1">
						<div className="container">
							<div className="row">

								<div className="col-lg-7 col-md-7 m-b30">
									{/* <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.599047659887!2d77.7012104!3d12.9622671!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb52fcb3ad37e0ec3!2sPro%20Stack%20Academy!5e0!3m2!1sen!2sin!4v1655809732474!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
									<form className="contact-bx ajax-form">
										<div className="ajax-message"></div>
										<div className="heading-bx left">
											<h3 className="title-head">Get In <span>Touch</span></h3>
											<p>Submit your information for Training and Placement details, we are happy to help you quickly </p>
										</div>
										<div className="row placeani">
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input 
															name="name" 
															value={formValues?.name} 
															type="text" 
															placeholder="Your Name" 
															className="form-control valid-character"
															onChange={(e)=>{
																setFormValues((prevState)=>({
																	...prevState,
																	name: e.target.value
																}))
																setInputValidation((prevState)=>({
																	...prevState,
																	name: ""
																}))
															}}/>
														{inputValidation?.name && <p className='form-error-message'>{inputValidation?.name}</p>}
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input 
															name="email" 
															value={formValues?.email} 
															type="text" 
															placeholder="Your Email Address" 
															className="form-control"
															onChange={(e)=>{
																setFormValues((prevState)=>({
																	...prevState,
																	email: e.target.value
																}))
																setInputValidation((prevState)=>({
																	...prevState,
																	email: ""
																}))
															}}/>
															{inputValidation?.email && <p className='form-error-message'>{inputValidation?.email}</p>}
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input 
															name="mobile" 
															value={formValues?.mobile} 
															type="text" 
															placeholder="Your Phone" 
															className="form-control int-value" 
															onChange={(e)=>{
																setFormValues((prevState)=>({
																	...prevState,
																	mobile: e.target.value
																}))
																setInputValidation((prevState)=>({
																	...prevState,
																	mobile: ""
																}))
															}}/>
															{inputValidation?.mobile && <p className='form-error-message'>{inputValidation?.mobile}</p>}
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														{/* <input name="subject" type="text" placeholder="Subject" required className="form-control" /> */}
														<select 
															class="input-form-select form-control" 
															name="course" 
															value={formValues?.course}
															onChange={(e)=>{
																setFormValues((prevState)=>({
																	...prevState,
																	course: e.target.value
																}))
																setInputValidation((prevState)=>({
																	...prevState,
																	course: ""
																}))
															}}>
															<option selected className='select-options' value="">Select Course</option>
															<option value="Java Full Stack Developer" className='select-options'>Java Full Stack Developer</option>
															<option value="Python Full Stack Developer" className='select-options'>Python Full Stack Developer</option>
															<option value="Full Stack Developer" className='select-options'>Full Stack Developer</option>
														</select>
														{inputValidation?.course && <p className='form-error-message'>{inputValidation?.course}</p>}
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<textarea 
															name="textMessage" 
															value={formValues?.textMessage} 
															placeholder="Type Message" 
															rows="4" 
															className="form-control" 
															onChange={(e)=>{
																setFormValues((prevState)=>({
																	...prevState,
																	textMessage: e.target.value
																}))
																setInputValidation((prevState)=>({
																	...prevState,
																	textMessage: ""
																}))
															}}
															 >
														</textarea>
														{inputValidation?.textMessage && <p className='form-error-message'>{inputValidation?.textMessage}</p>}
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<button 
													name="submit" 
													type="submit" 
													value="Submit" 
													class="btn button-md"
													onClick={(e)=>validationHandler(e)}> Send Message</button>
											</div>
										</div>
									</form>

								</div>

								<div className="col-lg-5 col-md-5 m-b30">
									<ContactInfoCard />
								</div>
							</div>
						</div>

						{/* Google Map */}
						<div className="container">
							<div className="row">

								<div className="col-lg-12 col-md-12">
									<iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.599047659887!2d77.7012104!3d12.9622671!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb52fcb3ad37e0ec3!2sPro%20Stack%20Academy!5e0!3m2!1sen!2sin!4v1655809732474!5m2!1sen!2sin" width="100%" height="350" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
								</div>
							</div>
						</div>

					</div>

				</div>

			</div>

			<Footer />
		</>
	);
}

export default Contact;