import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header/Header1";
import Footer from "../layout/footer/Footer";

// Elements
import FeatureContent2 from '../elements/Feature-Content1';
import Counter from '../elements/counter/Counter';
import OurStory1 from '../elements/our-story/Our-Story1';
import Testimonial1 from '../elements/Testimonial1';

// Images
import bannerImg from '../../images/banner/banner2.jpg';


class HireDevelopers extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Hire Pro Stack Developer - Zero Cost</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">Home</Link></li>
								<li>Hire Developer</li>
							</ul>
						</div>
					</div>

					<div className="content-block">

						<div className="section-area section-sp1">
							<div className="container">
								<FeatureContent2 />
							</div>
						</div>

						{/* 	<OurStory1 /> */}

						<Counter />

						{/* 	<Testimonial1 /> */}

					</div>

				</div>

				<Footer />

			</>
		);
	}
}

export default HireDevelopers;