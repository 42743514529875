import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
// Layout
import Header from "../layout/header/Header1";
import Footer from "../layout/footer/Footer";
import {listofData} from './Data';
// Elements
import PortfolioContent from "../elements/Portfolio-Content";

// Images
import bannerImg from '../../images/banner/banner1.jpg';

// mui
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination
} from '@mui/material';

const PlacementList = () => {
	// const [isSort, setisSort] = useState(false);
	const [nameSort, setnameSort] = useState(false);
	const [companySort, setcompanySort] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const filterByName = nameSort === true ? (a, b) => {
		if (a.name < b.name)
			return -1;
		if (a.name > b.name)
			return 1;
		return 0;
	} : () => { };
	const filterByCompany = companySort === true ? (a, b) => {
		if (a.company < b.company)
			return -1;
		if (a.company > b.company)
			return 1;
		return 0;
	} : () => { };
	return (<>
		<Header />

		<div className="page-content">

			<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
				<div className="container">
					<div className="page-banner-entry">
						<h1 className="text-white">Portfolio</h1>
					</div>
				</div>
			</div>
			<div className="breadcrumb-row">
				<div className="container">
					<ul className="list-inline">
						<li><Link to="/">Home</Link></li>
						<li>Placement List</li>
					</ul>
				</div>
			</div>

			<div className="content-block">

				<div className="section-area section-sp1 gallery-bx placementList_sec">
					<div className="container">
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell 
											onClick={() =>{
												// setisSort(!isSort)
												setnameSort(true)
												setcompanySort(false)
											}} 
											style={{ cursor: "pointer" }}>
											NAME
										</TableCell>
										<TableCell 
											onClick={() =>{
												// setisSort(!isSort)
												setcompanySort(true)
												setnameSort(false)
											}} 
											style={{ cursor: "pointer" }}>
											COMPANY</TableCell>
										<TableCell>SALARY</TableCell>
										<TableCell>LINKED IN</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{listofData?.sort(nameSort===true?filterByName:companySort===true?filterByCompany:()=>{})?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((items,index) => (
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell>{items?.Id}</TableCell>
											<TableCell>{items?.Name}</TableCell>
											<TableCell>{items?.Company}</TableCell>
											<TableCell>{items?.Salary}</TableCell>
											<TableCell>
												{
													items?.LinkedIn.length?<>
														<a href={items?.LinkedIn} target="blank">{items?.LinkedIn}</a>
													</>:<>----</>
												}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							<TablePagination
								// rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={listofData.length}
								rowsPerPage={rowsPerPage}
								className="placementlist_pagination"
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</TableContainer>
					</div>
				</div>

			</div>

		</div>

		<Footer />
	</>)
}

export default PlacementList;