import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import logo from '../../../images/logo.png';
import adv from '../../../images/adv/adv.jpg';

class Header extends Component {

	componentDidMount() {

		// Search Form Popup
		var searchBtn = document.getElementById("quik-search-btn")
		var searchForm = document.querySelector(".nav-search-bar")
		var closeBtn = document.getElementById("search-remove")

		searchBtn.addEventListener('click', function () {
			searchForm.classList.add("show")
		})

		closeBtn.addEventListener('click', function () {
			searchForm.classList.remove("show")
		})

		// Mobile Menu sidebar function
		var btn = document.querySelector('.menuicon');
		var nav = document.querySelector('.menu-links');

		function toggleFunc() {
			btn.classList.toggle("open");
			nav.classList.toggle("show");
		}

		btn.addEventListener('click', toggleFunc);

		// Mobile Submenu open close function
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener('click', function () { menuClick(this) });
		}

		function menuClick(current) {
			const active = current.classList.contains("open")
			navMenu.forEach(el => el.classList.remove('open'));

			if (active) {
				current.classList.remove('open')
				console.log("active")
			} else {
				current.classList.add('open');
				console.log("close")
			}
		}

	}

	render() {
		return (
			<>
				<header className="header rs-nav header-transp arent">
					<div className="top-bar">
						<div className="container">
							<div className="row d-flex justify-content-between">
								<div className="topbar-left">
									<ul>
										<li><Link to="/qa"><i className="fa fa-question-circle"></i>Interview Q & A</Link></li>
										<li><a href="mailto:hire@prostackacademy.com" className="cursor-default"><i className="fa fa-envelope-o"></i>hire@prostackacademy.com</a></li>
										{/* <li><Link to="/"><i className="fa fa-envelope-o"></i>info@prostackacademy.com</Link></li> */}
									</ul>
								</div>
								<div className="topbar-right">
									<ul>

										{/* <li>+91 - 9591 61 91 91</li>
										<li><Link to="/placements">Placements</Link></li> */}
										<li><a href="tel:+91 - 9591 61 91 91" className="cursor-default">+91 - 9591 61 91 91</a></li>
										<li><a href="tel:+91 - 9557 740  960" className="cursor-default">+91 - 9557 740  960</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to="/" style={{ cursor: "pointer" }}><img src={logo} alt="" className="cursor-default" /></Link>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											<li><Link to="#" className="btn-link"><i className="fa fa-facebook"></i></Link></li>

											<li><Link to="#" className="btn-link"><i className="fa fa-linkedin"></i></Link></li>
											{/* <!-- Search Button ==== --> */}
											<li className="search-btn"><button id="quik-search-btn" type="button" className="btn-link"><i className="fa fa-search"></i></button></li>
										</ul>
									</div>
								</div>
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-start" id="menuDropdown">
									<div className="menu-logo">
										<Link to="/"><img src={logo} alt="" /></Link>
									</div>
									<ul className="nav navbar-nav">
										<li className="active"><Link to="/">Home</Link></li>

										<li className="add-mega-menu">
											<Link to="/courses">Programs <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu add-menu">
												<li className="add-menu-left">

													<ul>
														{/* <li><Link to="/courses">Courses </Link></li> */}
														<li><Link to="/java-full-stack-developer">Java Full Stack  Developer</Link></li>
														<li><Link to="/python-full-stack-developer">Python Full Stack Web Developer</Link></li>
														<li><Link to="/full-stack-developer">Full Stack Web Developer</Link></li>
														<li><Link to="/profile">Instructor Profile</Link></li>

													</ul>
												</li>
												<li className="add-menu-right">
													<img src={adv} alt="" />
												</li>
											</ul>
										</li>
										{/* <li><Link to="/projects">Projects</Link></li> */}
										<li><Link to="/hire-developers">Hire Developers</Link></li>
										<li><Link to="/placements">Placements <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li><Link to="/placements">Placements</Link></li>
												<li><Link to="/placement-list">Placement List</Link></li>
											</ul>
										</li>
										{/* <li><Link to="/blog">Blog</Link></li> */}
										<li><Link to="/contact">Contact Us</Link></li>
										<li><Link to="/practice">Practice</Link></li>
									</ul>

									<div className="nav-social-link">
										<Link to="#"><i className="fa fa-facebook"></i></Link>

										<Link to="#"><i className="fa fa-linkedin"></i></Link>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> */}
					<div className="nav-search-bar">
						<form action="#">
							<input name="search" type="text" className="form-control" placeholder="Type to search" />
							<span><i className="ti-search"></i></span>
						</form>
						<span id="search-remove"><i className="ti-close"></i></span>
					</div>
				</header>
			</>
		);
	}
}

export default Header;
