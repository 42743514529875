import React, { Component, useEffect } from 'react';
import { useStateValue } from "../../store/state";
// Layout
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
// Elements
import MainSlider from '../elements/slider/Slider1';
import ThreeCourses from '../elements/ThreeCourses';
import OnlineCourses from '../elements/Online-Courses';
import Testimonial2 from '../elements/Testimonial2';
import PopularCoursesSlider from '../elements/Popular-Courses-Slider';
// import Upcomingevents from '../elements/Events-Content2';
import UpcomingEventSlider from '../elements/Upcoming-Event-Slider';
import RecentNewsSlider from '../elements/Recent-News-Slider';
import SuccessStory from '../elements/Success-Story';

const Index = () => {
	const [{ }, dispatch] = useStateValue();
	/* useEffect(() => {
		dispatch({
			type: "HOME__POPUP",
			value: true
		})
	}, []) */
	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<MainSlider />
				<div className="content-block" id="content-area">
					<ThreeCourses />
					<PopularCoursesSlider />
					{/* <Upcomingevents /> */}
					{/* 	<OnlineCourses /> */}
					<SuccessStory />
					{/* 	<Testimonial2 /> */}
					{/* 	<RecentNewsSlider /> */}
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Index;