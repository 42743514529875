import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import { useStateValue } from "../../store/state";

// Layout
import Header from "../layout/header/Header1";
import Footer from "../layout/footer/Footer";

// Images
import bannerImg from '../../images/banner/banner2.jpg';
import testiPic1 from '../../images/testimonials/pic1.jpg';
import testiPic2 from '../../images/testimonials/pic2.jpg';
import blogDefaultThum1 from '../../images/blog/default/thum1.jpg';

const JavaFullStack = ()=>{
	const [{ }, dispatch] = useStateValue();
	/* useEffect(()=>{
		setTimeout(() => {
			dispatch({
				type: "HOME__POPUP",
				value: true
			})
		}, 2000);
	},[]) */
	return (
		<>
			<Header />
			<div className="page-content">
				<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
					<div className="container">
						<div className="page-banner-entry">
							<h1 className="text-white">Java Full Stack Developer</h1>
						</div>
					</div>
				</div>
				<div className="breadcrumb-row">
					<div className="container">
						<ul className="list-inline">
							<li><Link to="/">Home</Link></li>
							<li>Java Full Stack Developer</li>
						</ul>
					</div>
				</div>

				<div className="content-block">

					<div className="section-area section-sp1">
						<div className="container">
							<div className="row d-flex flex-row-reverse">
								<div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 m-b30">
									<div className="course-detail-bx">
										<div className="course-price">

											<h5>+91 9591 61 91 91</h5>
										</div>
										<div className="course-buy-now text-center">
											<Link to="/contact" className="btn radius">Call Us More Details</Link>
										</div>
										<div className="teacher-bx">
											<div className="teacher-info">
												<div className="teacher-thumb">
													<img src={testiPic1} alt="" />
												</div>
												<div className="teacher-name">
													<h5>Narasimha Reddy</h5>
													<span>Full Stack Developer</span>
												</div>
											</div>
										</div>
										<div className="cours-more-info">
											<div className="review">
												<span>5 Review</span>
												<ul className="cours-star">
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
												</ul>
											</div>
											<div className="price categories">
												<span>Coding</span>
												<h5 className="text-primary">Full Stack</h5>
											</div>
										</div>
										<div className="course-info-list scroll-page">
											<ul className="navbar">
												<li>
													<ScrollTo smooth={true} activeClass="active" spy={true} className="nav-link" to={"overview"}>
														<i className="ti-zip"></i> Overview
													</ScrollTo>
												</li>
												<li>
													<ScrollTo smooth={true} activeClass="active" spy={true} className="nav-link" to={"curriculum"}>
														<i className="ti-bookmark-alt"></i> Curriculum
													</ScrollTo>
												</li>
												<li>
													<ScrollTo smooth={true} activeClass="active" spy={true} className="nav-link" to={"instructor"}>
														<i className="ti-user"></i> Instructor
													</ScrollTo>
												</li>
												<li>
													<ScrollTo smooth={true} activeClass="active" spy={true} className="nav-link" to={"reviews"}>
														<i className="ti-comments"></i> Reviews
													</ScrollTo>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
									<div className="courses-post">
										<div className="ttr-post-media media-effect">
											<img src={blogDefaultThum1} alt="Java Full Stack Developer Training" />
										</div>
										<div className="ttr-post-info m-b30">
											<div className="ttr-post-title ">
												<h2 className="post-title">Java Full Stack Developer - Master Program</h2>
											</div>
											<div className="ttr-post-text">
												<p>Learn and Expertise on Java Fulll Stack Developer with React JS, Java, Java8 Featuers and Spring Boot Framework with Real-time Projects.Full Stack Developer is a skilled developer who can design develop and deploy application's back-end and front-end. Java full stack Developer is basically a term used for a Web Application Development that uses Java, java8 and Spring Boot  to develop the entire technology stack is referred to as Java Full Stack Developer.</p>
											</div>
										</div>
									</div>
									<div className="courese-overview" id="overview">
										<h4>Overview</h4>
										<div className="row">
											<div className="col-md-12 col-lg-4">
												<ul className="course-features">
													<li><i className="ti-book"></i> <span className="label">Training Sessions</span> <span className="value">100+</span></li>
													<li><i className="ti-help-alt"></i> <span className="label">Assignments</span> <span className="value">400+</span></li>
													<li><i className="ti-time"></i> <span className="label">Duration</span> <span className="value">100 hours</span></li>
													<li><i className="ti-stats-up"></i> <span className="label">Skill level</span> <span className="value">Beginner</span></li>
													<li><i className="ti-smallcap"></i> <span className="label">Language</span> <span className="value">English</span></li>
													<li><i className="ti-user"></i> <span className="label">Students</span> <span className="value">25 Per Batch</span></li>
													<li><i className="ti-check-box"></i> <span className="label">Bootcamp Training</span> <span className="value">Yes</span></li>
												</ul>
											</div>
											<div className="col-md-12 col-lg-8">
												<h5 className="m-b10">About Java Full Stack</h5>
												<p>Full Stack Developer is a skilled developer who can design develop and deploy application's back-end and front-end. Java full stack Developer is basically a term used for a Web Application Development that uses Java, java8 and Spring Boot  to develop the entire technology stack is referred to as Java Full Stack Developer.</p>

												<h5 className="m-b10">Learning Outcomes</h5>
												<ul className="list-checked primary">
													<li>Over 80 lectures and 120 hours of content</li>
													<li>Assignments and Real-time Projects</li>
													<li>Practical assignments at the end of every session.</li>
													<li>Boot-camp Training Program</li>
													<li>Hard-copy Material(UI, JavaScript, React JS, Java)</li>
													<li>Offline Training and Course Video Recordings - Life Time Access</li>
													<li>Resume Preparation</li>
													<li>Mock Interview's</li>
													<li>Placements - 100 %</li>
													<li>Develop and Deploy End-to-End Web Application</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="m-b30" id="curriculum">
										<h4>Java Full Stack - Curriculum</h4>
										<ul className="curriculum-list">
											<li>
												<h5>Full Stack Developer - Demo</h5>
												<ul>
													<li>
														<div className="curriculum-list-box">
															<span>Session 1.</span>Introduction, About Full Stack, Career Guidence
														</div>
														<span>90 minutes</span>
													</li>
												</ul>
											</li>
											<li>
												<h5>Frond-end </h5>
												<ul>
													<li>
														<div className="curriculum-list-box">
															<span>Session 2.</span>         Git,Github and Github Setup
														</div>
														<span>90 minutes</span>
													</li>
													<li>
														<div className="curriculum-list-box">
															<span>Session 3-15.</span> Web Technologies - HTML,CSS,Bootstrap
														</div>
														<span>10 Days/15 Hours</span>
													</li>
													<li>
														<div className="curriculum-list-box">
															<span>Session 16-30.</span> Javascript, ES6
														</div>
														<span>20 Days/30 Hours</span>
													</li>
													<li>
														<div className="curriculum-list-box">
															<span>Session 31-50.  </span>React,Redux and React Hooks
														</div>
														<span>25 Days/25 Hours</span>
													</li>
												</ul>
											</li>
											<li>
												<h5>Back-End </h5>
												<ul>
													<li>
														<div className="curriculum-list-box">
															<span>Session 1-30.</span>Java, Java 8 New features
														</div>
														<span>35 Days/40 Hours</span>
													</li>
													<li>
														<div className="curriculum-list-box">
															<span>Session 31 - 40.</span> JDBC, JSP Basics
														</div>
														<span>5 Days</span>
													</li>
													<li>
														<div className="curriculum-list-box">
															<span>Session 40-55.</span> Spring Boot
														</div>
														<span>15 Days</span>
													</li>
												</ul>
											</li>
											<li>
												<h5>Projects</h5>
												<ul>
													<li>
														<div className="curriculum-list-box">
															<span>Objective 1.</span>Website design
														</div>
														<span>2 Days</span>
													</li>
													<li>
														<div className="curriculum-list-box">
															<span>Objective 2.</span>10 +React Demo Apps - to cover complete React Concepts
														</div>
														<span> - </span>
													</li>
													<li>
														<div className="curriculum-list-box">
															<span>Objective 3.</span>4+ Spring Boot Demo Apps - to cover complete React Concepts
														</div>
														<span>-</span>
													</li>
													<li>
														<div className="curriculum-list-box">
															<span>Objective 4.</span>Develop and Deploy - End-to-End Java Full Stack App
														</div>
														<span>-</span>
													</li>
												</ul>
											</li>
										</ul>
									</div>
									<div className="" id="instructor">
										<h4>Instructor</h4>
										<div className="instructor-bx">
											<div className="instructor-author">
												<img src={testiPic1} alt="" />
											</div>
											<div className="instructor-info">
												<h6>Narasimha Reddy </h6>
												<span>Full Stack Developer</span>
												<ul className="list-inline m-tb10">
													<li><Link to={{ pathname: 'https://www.linkedin.com/in/narasimhareddyk/' }} target="_blank" className="btn sharp-sm linkedin"><i className="fa fa-linkedin"></i></Link></li>
													<li><Link to={{ pathname: 'https://github.com/narasimhareddyprostack' }} target="_blank" className="btn sharp-sm github"><i className="fa fa-github"></i></Link></li>
												</ul>
												<p className="m-b0">A well organized highly experienced professional with IT industrial experience (Full Stack) of over 14 years in consulting and solution industries.</p>
												<p className="m-b0"><b>Trainings:</b> 110+ Batches completed successfully on Full Stack Web Developer, React Js, Java, Spring Boot, Python and  UI Technologies.</p>
											</div>
										</div>

									</div>
									<div className="" id="reviews">
										<h4>Google Reviews</h4>

										<div className="review-bx">
											<div className="all-review">
												<h2 className="rating-type">5</h2>
												<ul className="cours-star">
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
													<li className="active"><i className="fa fa-star"></i></li>
												</ul>
												<span>4.9 Rating</span>
											</div>
											<div className="review-bar">
												<div className="bar-bx">
													<div className="side">
														<div>5 star</div>
													</div>
													<div className="middle">
														<div className="bar-container">
															<div className="bar-5" style={{ width: "90%" }}></div>
														</div>
													</div>
													<div className="side right">
														<div>235</div>
													</div>
												</div>
												<div className="bar-bx">
													<div className="side">
														<div>4 star</div>
													</div>
													<div className="middle">
														<div className="bar-container">
															<div className="bar-5" style={{ width: "70%" }}></div>
														</div>
													</div>
													<div className="side right">
														<div>10</div>
													</div>
												</div>
												<div className="bar-bx">
													<div className="side">
														<div>3 star</div>
													</div>
													<div className="middle">
														<div className="bar-container">
															<div className="bar-5" style={{ width: "50%" }}></div>
														</div>
													</div>
													<div className="side right">
														<div>1</div>
													</div>
												</div>
												<div className="bar-bx">
													<div className="side">
														<div>2 star</div>
													</div>
													<div className="middle">
														<div className="bar-container">
															<div className="bar-5" style={{ width: "40%" }}></div>
														</div>
													</div>
													<div className="side right">
														<div>0</div>
													</div>
												</div>
												<div className="bar-bx">
													<div className="side">
														<div>1 star</div>
													</div>
													<div className="middle">
														<div className="bar-container">
															<div className="bar-5" style={{ width: "20%" }}></div>
														</div>
													</div>
													<div className="side right">
														<div>1</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>

							</div>
						</div>
					</div>

				</div>

			</div>

			<Footer />

		</>
	);
}

export default JavaFullStack;