import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ContactInfoCard extends Component {
	render() {
		return (
			<>
				<div className="bg-primary text-white contact-info-bx">
					<div className="heading-bx left mb-4">
						<h3 className="m-b10 title-head">Contact <span> Info</span></h3>
						<p className="m-b0">We are happy to help you</p>
					</div>
					<div className="widget widget_getintuch">
						<ul>
							<li><i className="ti-location-pin"></i>Pro Stack Academy, #96, Ground Floor, R J Garden, Chinnapanahalli, Marathahalli, Bangalore - 37 </li>
							<li><i className="ti-mobile"></i>9591 61 91 91 (24/7 WhatsApp)</li>
							<li><i className="ti-email"></i>info@prostackacademy.com</li>
						</ul>
					</div>
					<h5 className="m-t0 m-b20">Follow Us</h5>
					<ul className="list-inline contact-social-bx m-b0">
						<li><Link to={{ pathname: "https://www.facebook.com/prostackacademyindia" }} target="_blank" className="btn outline radius"><i className="fa fa-facebook"></i></Link></li>
						<li><Link to={{ pathname: "https://twitter.com/prostackacademy" }} target="_blank" className="btn outline radius"><i className="fa fa-twitter"></i></Link></li>
						<li><Link to={{ pathname: "https://www.linkedin.com/company/prostackacademy/" }} target="_blank" className="btn outline radius"><i className="fa fa-linkedin"></i></Link></li>

					</ul>
				</div>
			</>
		);
	}
}

export default ContactInfoCard;